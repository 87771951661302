@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@500&display=swap");
/* font-family: 'Montserrat', sans-serif; */
@import url("https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@500,600,700&display=swap");
/* font-family: 'Nunito Sans', sans-serif; */
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700;800&display=swap");
/* font-family: 'Open Sans', sans-serif; */

:root {
  --black--: #000;
  --white--: #fff;
  --prime--: #524ae3;
  --blackPrime--: #0f172a;
  --grayPrime--: #8d8d8d;
  --inputBorder--: #cbd5e1;
  --btnPrime--: #524ae3;
  --gray--: #656565;
  --pink--: #cb198e;
}

/* ========================================= Login Page CSS ========================== */
.fontLogin {
  color: #ff7500 !important;
}

.blackPrime {
  background-color: #0f172a;
}

.mainLoginPage {
  display: flex;
  justify-items: center;
  width: 100% !important;
  height: 100vh !important;
  background: url(../images/w1.jpg);
  background-size: cover !important;
  background-repeat: no-repeat !important;
  background-position: center !important;
}

.mainLoginPage .loginDiv {
  /* border-radius: 16px; */
  width: 440px;
  margin: auto;
  /* box-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1) !important; */
  overflow: hidden;
  top: 220px;
  right: 120px;
  position: absolute;
}

@media screen and (max-width: 768px) and (min-width: 426px) {
  .mainLoginPage .loginDiv {
    /* top: 105px; */
    left: 137px;
  }

  .mainLoginPage {
    display: flex;
    justify-items: center;
    width: 100% !important;
    height: 100vh !important;
    background: none !important;
    background-size: cover !important;
    background-repeat: no-repeat !important;
    background-position: center !important;
    background-color: #fff !important;
  }
}

@media screen and (max-width: 2560px) and (min-width: 1025px) {
  .mainLoginPage .loginDiv {
    top: 345px;
    right: 645px;
  }
}

@media screen and (max-width: 1920px) and (min-width: 1441px) {
  .mainLoginPage .loginDiv {
    top: 250px;
    right: 395px;
  }
}

@media screen and (max-width: 1440px) and (min-width: 1024px) {
  .mainLoginPage .loginDiv {
    top: 185px;
    right: 215px;
  }
}

@media screen and (max-width: 425px) {
  .mainLoginPage .loginDiv {
    /* top: 345px; */
    left: 0px;
    width: inherit;
  }

  .mainLoginPage {
    display: flex;
    justify-items: center;
    width: 100% !important;
    height: 100vh !important;
    background: none !important;
    background-size: cover !important;
    background-repeat: no-repeat !important;
    background-position: center !important;
    background-color: #fff !important;
  }
}

@media screen and (max-width: 1024px) and (min-width: 769px) {
  .mainLoginPage .loginDiv {
    top: 170px;
    right: 65px;
  }
}

.mainLoginPage .loginPage {
  background-color: initial;
  padding: 40px;
}

.mainLoginPage .form-input {
  width: 100% !important;
  /* height: 60px !important; */
  border: 1px solid var(--inputBorder--) !important;
  border-radius: 6px;
  padding: 16px;
  font-size: 16px;
  cursor: pointer;
}

.mainLoginPage .form-input:focus {
  border: 2px solid #f7a901bb !important;
  border-left: none !important;
  outline: none;
  background-color: #fefdff !important;
}

.mainLoginPage .loginLogo {
  height: 100px;
  width: 100px;
  background-color: var(--white--);
  border-radius: 50%;
}

.logoBar {
  height: 120px;
  /* background-color: var(--blackPrime--); */
  padding: 20px;
}

.loginIcons {
  border-radius: 6px 0 0 6px;
  width: 50px;
}

.loginFields {
  border-radius: 0 6px 6px 0 !important;
}

.sigIn-btn {
  border: 1px solid var(--inputBorder--) !important;
}

.forgetPassword {
  /* color: var(--prime--) !important; */
  cursor: pointer;
}

/* ========================================= Navbar Page CSS ========================== */
/* .boxNav{
  cursor: pointer;
  margin: 0 12px;
  position: -webkit-sticky;
  position: sticky;
  top: 1px;
  position: static;
  transition: 0.3s;
 z-index: 11;
} */

.mainNavbar {
  position: -webkit-sticky;
  position: sticky;
  transition: 0.3s !important;
  z-index: 1000001;
  top: 0;
  background-color: #191919;
}

.boxNav {
  cursor: pointer;
  padding: 9px 12px;
}

.navBar {
  -webkit-backdrop-filter: saturate(180%) blur(8px);
  backdrop-filter: saturate(180%) blur(8px);
  background-color: #454545;
  border-radius: 15px;
  box-shadow: 0 0 10px #00000013;
  font-size: 18px;
  /* margin: 0 10px; */
}

.mainContainer {
  height: 100vh;
  overflow: hidden;
}

.containerRight {
  height: 100vh;
  overflow: scroll;
}

.mainAdmin {
  /* margin-top: 20px; */
  transition: 0.3s;
  height: calc(100vh - 87.98px);
  background: #f1f5f9;
  border-radius: 1rem 1rem 0rem 0rem;
  overflow-y: scroll !important;
  overflow-x: hidden !important;
}

/* Nav Menu Toggle JS CSS */
/* .webNav {
  margin-left: 220px;
}

.mobNav {
  margin-left: 75px;
} */

/* Mobile Size */
/* @media screen and (max-width: 992px) {
  .webNav {
    margin-left: 75px;
  }

  .mobNav {
    margin-left: 220px;
  }
}   */

/* ========================================= Sidebar Page CSS ========================== */

/* Side Menu Toggle JS CSS */
.webSidebar {
  width: 258px;
}

.mobSidebar {
  width: 83px;
}

.sideBar.mobSidebar .sideBarLogo span {
  display: none;
}

.navToggle {
  position: relative;
}

.sideBar.mobSidebar .mainMenu li a>div>span,
.sideBar.mobSidebar .mainMenu>li>a>i,
.sideBar.mobSidebar .subMenu li a span {
  display: none;
}

.sideBar.mobSidebar .mainMenu li a {
  justify-content: start;
}

.sideBar.mobSidebar .mainMenu li a i {
  margin: 0;
}

.sideBar.mobSidebar .subMenu li a {
  padding-left: 22px;
}

.mobSidebar .navigation>.menuName {
  text-align: center !important;

}

.webSidebar .navigation>.menuName {
  text-align: unset !important;

}

/* End Side Menu Toggle JS CSS */

/* Mobile Size */
@media screen and (max-width: 992px) {

  .containerLeft {
    position: absolute;
    left: 0;
    z-index: 9999;
  }

  .mobAdmin {
    opacity: 0.4;
  }

  .webSidebar {
    width: 83px !important;
    left: -83px !important;
    position: absolute !important;
  }

  .sideBar .sideBarLogo span {
    display: none;
  }

  .sideBar .mainMenu li a>div>span,
  .sideBar .mainMenu>li>a>i,
  .sideBar .subMenu li a span {
    display: none;
  }

  .sideBar .mainMenu li a {
    justify-content: center;
  }

  .sideBar .mainMenu li a i {
    margin: 0;
  }

  .sideBar .subMenu li a {
    padding-left: 20px;
  }

  /* ============ */

  .mobSidebar {
    width: 220px;
  }

  .sideBar.mobSidebar .sideBarLogo span {
    display: block;
  }

  .sideBar.mobSidebar .mainMenu li a>div>span,
  .sideBar.mobSidebar .mainMenu>li>a>i,
  .sideBar.mobSidebar .subMenu li a span {
    display: block;
  }

  .sideBar.mobSidebar .mainMenu li a>div {
    display: flex;
    align-items: center;
  }

  .sideBar.mobSidebar .mainMenu>li>a {
    justify-content: space-between;
    align-items: center;
  }

  .sideBar.mobSidebar .mainMenu li a i {
    margin-right: 10px;
  }

  .sideBar.mobSidebar .subMenu li a {
    padding-left: 30px;
  }
}

.rotate90 {
  transform: rotate(90deg);
  transition: 0.3s;
}

.mainSidebar {
  position: relative;
}

.navigation::-webkit-scrollbar-thumb {
  background: transparent;
  border-radius: 5px;
}

.navigation::-webkit-scrollbar {
  width: 0px;
}

.sideMenuBg {
  position: absolute;
  top: 10px;
  right: 0px;
  fill: #f1f5f9;
}

.toggleIconNav {
  position: absolute;
  right: -13px;
  top: 50px;
  overflow: visible !important;
  transition: 0.3s;
  z-index: 2000;
}

.toggleIcon {
  transition: transform 0.3s;
}

.rotated {
  transform: rotate(90deg);
  transition: 0.3s;
}

.sideBar {
  /* height: 100vh; */
  /* background-color: var(--white--) !important; */
  background-color: #191919;
  color: var(--gray--) !important;
  transition: 0.3s;
  /* overflow: hidden; */
}

.abb {
  width: 0;
  height: 0;
  border-top: 50px solid transparent;
  border-right: 100px solid red;
  border-bottom: 50px solid transparent;
}

.sideBarLogo {
  /* border-bottom: 1px solid #434343; */
  padding: 24px;
}

.navigation {
  padding: 0px 10px;
  max-height: 88vh;
  margin-top: 25px;
  overflow-y: scroll !important;
}

.mainMenu>li {
  padding-bottom: 5px;
}

.mainMenu li a {
  color: var(--grayPrime--);
  font-size: 16px;
  font-weight: 700;
  padding: 10px 15px;
  border-radius: 6px;
  display: flex;
  justify-content: space-between;
  transition: 0.3s;
  align-items: center;
  margin-bottom: 5px;
}

.mainMenu li>a svg path {
  transition: 0.3s;
  fill: var(--grayPrime--);
}

.mainMenu li>a:hover,
.mainMenu .activeMenu {
  color: #ffffff;
  /* box-shadow: 0 0 5px; */
}

.mainMenu li>a:hover svg path,
.mainMenu .activeMenu svg path {
  fill: #ffffff;
}

.mainMenu li a i {
  margin-right: 10px;
  transition: 0.3s;
  height: 20px;
  width: 20px;
}

.mainMenu li a img {
  margin-right: 10px;
  transition: 0.3s;
  width: 17px;
  height: 17px;
}

.mainMenu li a svg {
  margin-right: 10px;
  transition: 0.3s;
  /* width: 17px;
  height: 17px; */
}

.content {
  overflow: hidden;
  max-height: 0;
  transition: max-height 0.5s ease-out;
}

.subMenu li a {
  justify-content: start;
  padding-left: 30px;
}

.subMenu li a i {
  font-size: 6px;
  display: flex;
  align-items: center;
}

/* ================================== Admin CSS =================================== */
/* 

/* Mobile Size */
@media screen and (max-width: 425px) {
  .pageCenterMob {
    display: flex;
    justify-content: center;
  }

  .pageSm {
    margin-top: 8px;
  }

  .smBTN {
    font-size: 12px;
    width: 47%;
    padding: 0%;
  }
}

@media screen and (min-device-width: 320px) and (max-device-width: 425px) {
  .fake-host-page .searching-box {
    width: 100%;
  }

}

/* ================================== GIFT & GIFT CATEGORY CSS  =================================== */

/*      CARD    */

.card {
  word-wrap: break-word;
  background-clip: border-box;
  /* background-color: #fff; */
  border: 1px solid transparent;
  border-radius: 0.3rem;
  /* box-shadow: 0 0.76rem 1.52rem rgba(18, 38, 63, 0.03); */
  display: flex;
  flex-direction: column;
  margin-bottom: 1.5rem;
  min-width: 0;
  position: relative;
  width: 100%;
  border-top: none;
  box-shadow: 0px 14px 40px -2px lightgray;
}

.add-button {
  background-color: "#D9386A" !important;
}

.edit-button {
  background-image: linear-gradient(to left,
      #48d6a8 0,
      #029666 100%) !important;
}

.delete-button {
  background-image: linear-gradient(45deg, #d9386a, #f7778c) !important;
}

.info-button {
  background-image: linear-gradient(to right,
      #46b7eb 0,
      #8cd8eb 100%) !important;
}

.categoryImage {
  height: 100px;
  width: 100px;
  object-fit: cover;
  /* border-radius: 50%; */
}

.copyCode:hover {
  color: rgb(225, 78, 132);
}

/* ===================================== Host Info Css ================================= */

.hostProfileMain {
  padding: 20px;
}

.profile-image {
  /* box-shadow: 0 0 15px #a2a1a1; */
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06) !important;
  /* width: 360px;
  height: 275px; */
  margin-left: 50px;
  max-width: 100%;
  object-fit: cover;
}

@media screen and (max-width: 600px) {
  .profile-image {
    height: 200px !important;
  }

  .hostImages img {
    height: 100px !important;
    width: 100px !important;
  }
}

@media screen and (max-width: 768px) {
  .profile-image {
    margin-left: 0 !important;
  }
}

.MuiRadio-colorSecondary.Mui-checked {
  color: rgb(54, 57, 148) !important;
}

.hostImages img {
  width: 120px;
  height: 120px;
  object-fit: cover;
  border-radius: 10px;
  max-width: 100%;
}

/* ================================== USER INFO CSS  =================================== */

.gradient-custom {
  /* fallback for old browsers */
  background: #f6d365;
  background: linear-gradient(to right bottom, rgb(255 255 255), rgb(92 92 92));
}

.profile {
  max-width: 100%;
  justify-content: center;
  display: flex;
}

.userData {
  font-family: "Open Sans", sans-serif;
  font-weight: 500;
}

@media screen and (max-width: 320px) {
  .profileImage {
    width: 110px !important;
    height: 110px !important;
    border-width: 1px !important;
  }

  .profileImage img {
    height: 110px !important;
    width: 110px !important;
  }
}

.imageCard,
.allDetails {
  border-radius: 10px;
}

.uTable tr:not(:last-child) {
  border-bottom: 1px solid #e6ebf1;
}

/* .uTable tr td:first-child {
  color: #664dc9;
} */

.uTable td {
  padding: 15px 0px !important;
  /* font-size: 13px; */
  /* font-weight: bold; */
}

.styles-module_Editext__button__sxYQX {
  max-width: 0px !important;
  padding: 0 !important;
  font-size: 12px !important;
  border-radius: 3px !important;
  border-width: 0px !important;
  border-style: none !important;
  margin-left: 4px;
  background-color: #eaeaea;
}

.styles-module_Editext__input__2-M50 {
  height: 0px;
  width: 50% !important;
}

.profileImage {
  width: 200px;
  height: 200px;
  border-width: 1px !important;
}

.profileImage img {
  object-fit: cover;
  width: 200px;
  height: 200px;
}

.hostProfileImage {
  border-radius: 50%;
  overflow: hidden;
}

@media screen and (min-width: 992px) and (max-width: 1310px) {
  .profileImage {
    width: 125px !important;
    height: 125px !important;
    border-width: 1px !important;
  }

  .profileImage img {
    height: 125px !important;
    width: 125px !important;
  }

  .profileSec-2 {
    height: 492px !important;
  }

  .dashDataBox {
    height: 492px !important;
  }
}

.boxUserInfo {
  border: 1px solid #97979769;
  /* /* width: 230px */
  border-radius: 5px;
  /* box-shadow: inset -2px 0px 0px 0px; */
}

.styles-module_Editext__editing_container__1C4d6 input:focus {
  border: none !important;
}

.fsuh {
  font-family: auto;
}

.fsu {
  font-size: 14px;
}

@media screen and (max-width: 320px) {
  .category-btn .btn-icon {
    padding: 3px 6px !important;
  }

  .category-btn button>i {
    font-size: 12px !important;
  }
}

.dropdown-menu li:hover a {
  background-color: rgba(183, 224, 240, 0.466) !important;
}

.dropdown-item {
  color: #000 !important;
}

/* ==================== Report css ================= */

/* report  */

.accordion-button::after {
  display: none;
}

.accordion-button:not(.collapsed),
.accordion-button:focus {
  border-color: #bcc0c569 !important;
  background-color: #bcc0c569 !important;
}

.accordion-button:focus {
  box-shadow: none !important;
}

.accordion-body {
  background-color: #bcc0c530 !important;
}

.fs-14 {
  font-size: 14px;
}

.reportTable {
  border-style: hidden !important;
}

/* ==================== Admin Profile css ================= */

.adminProfileBox {
  margin: 10px 0;
}

.dashBoxData {
  border-radius: 16px;
  /* box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.14),
  0 7px 10px -5px rgba(64, 64, 64, 0.4) !important; */
  padding: 10px;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06);
}

.adminProfileImg {
  padding: 10px;
}

.adminImg {
  width: 200px;
  height: 200px;
  border-radius: 50%;
}

/*======================== Dashboard css ====================  */

.dashIconBox {
  /* background-color: #e9e9e9; */
  /* padding: 25px; */
  font-size: 50px;
  background-image: url("../images/bgdark.png");
}

.dashBox {
  position: relative;
  font-family: "Nunito Sans", sans-serif;
}

.icon {
  z-index: 111;
}

.icon-data1 {
  background-color: #c0ebfe;
  color: #008ecd;
}

.icon-data2 {
  background-color: #d4dbfd;
  color: #4860df;
}

.icon-data3 {
  background-color: #ffdbe6;
  color: #e75448;
}

.icon-data4 {
  background-color: #d6ffd0;
  color: #4ca040;
}

.dashDataBox {
  border-radius: 16px;
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.14),
    1px -4px 4px -5px rgba(64, 64, 64, 0.4) !important;
}

.lineCss {
  opacity: 0.1;
  width: 85%;
  margin: 0 auto;
}

.chart1 {
  border-radius: 15px;
  box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.14),
    0 7px 10px -5px rgba(64, 64, 64, 0.4) !important;
}

.chartHeader {
  box-shadow: 0px 10px 10px -15px #111;
}

/*  fake host dialogue */
.excel-sheet-table th {
  white-space: nowrap;
}

.host-edit-multipleImg {
  display: flex;
  flex-direction: column;
}

.host-edit-multipleImg i {
  width: 84px;
  display: flex;
  justify-content: flex-end;
  margin-bottom: -5px;
  z-index: 111;
  font-size: 20px;
  cursor: pointer;
  color: #d93030;
}

@media screen and (min-device-width: 768px) and (max-device-width: 991px) {
  .dashboard-activity {
    display: flex;
    grid-template-columns: auto auto auto auto;
    /* gap: 16px; */
    width: 100%;
  }

  .dashboard-activity-one {
    margin-top: 16px;
    padding-right: 8px;
  }

  .dashboard-activity-one,
  .dashboard-activity-two {
    width: 100%;
  }

  .dashboard-activity-two {
    padding-left: 8px;
  }
}

@media screen and (min-device-width: 768px) and (max-device-width: 1199px) {

  .user-chart-col,
  .revenue-chart-col {
    width: 100%;
    margin-bottom: 25px;
  }

  .dashDataBox {
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
      0 2px 4px -1px rgba(0, 0, 0, 0.06);
  }

  .chart-box-row {
    display: flex;
    flex-direction: column-reverse;
    margin-top: 5px !important;
  }

  .dashboard-activity {
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
    padding: 8px;
    margin-bottom: 15px;
  }

  .dashboard-activity .dashboard-activity-one {
    /* margin-right: 0.5rem!important;
    margin-left: 0.5rem!important; */
    margin-top: 0px;
    height: unset !important;
  }

  .dashboard-activity .dashboard-activity-two {
    margin-top: 0px !important;
    /* margin-right: 0.5rem!important;
    margin-left: 0.5rem!important; */
    height: unset !important;
  }
}

@media only screen and (min-device-width: 1200px) {

  .dashboard-activity-one,
  .dashboard-activity-two {
    width: 100%;
    height: unset !important;
  }
}

@media only screen and (max-device-width: 767px) {
  .revenue-chart-col {
    margin-top: 20px;
  }

  .dashboard-activity-one,
  .dashboard-activity-two {
    margin-right: 0px !important;
    margin-top: 15px;
  }

  .dashboard-activity {
    padding: 8px;
  }
}

.topic-data-show {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px;
  padding: 19px;
}

.add-detail-btn {
  background-color: rgb(25, 135, 84);
  border-radius: 50%;
  height: 34px;
  width: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 18px !important;
}

.topic-list-chip .MuiChip-root {
  margin: 5px;
  background-color: #fff;
}

.topic-list-chip-host .MuiChip-root {
  margin: 5px;
  background-color: #fff;
}

.topicFontSize .prime-input label {
  font-size: 20px;
}

.topic-list-chip {
  max-height: 260px;
  min-height: 310px;
}

.MuiChip-outlined .MuiChip-deleteIcon:hover {
  color: #f1636aa6;
}

.topicFontSize .form-input {
  height: 38px !important;
}

.disabled {
  opacity: 0.6;
  cursor: not-allowed !important;
}

.AppShadow {
  box-shadow: -1px 1px 3px rgba(0, 0, 0, 0.5);
}

.MoniBoxShadow {
  box-shadow: -1px 1px 3px rgba(0, 0, 0, 0.5);
}

.box {
  background-color: #ebe4e4;
  padding: 10px;
  margin: 12px 0;
  text-align: center;
}

.story>#Iframe>html>body>img,
.story>#Iframe>html>body>video {
  display: block !important;
  -webkit-user-select: none !important;
  object-fit: cover !important;
  box-sizing: border-box !important;
  height: 100px !important;
  width: 100px !important;
}

.cursor-pointer {
  cursor: pointer !important;
}

.categoryBox {
  transition-duration: .25s;
}

.categoryBox:hover {
  scale: 1.03;
}

.MuiChip-deleteIcon {
  cursor: pointer !important;
}

.imageGrid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  /* Three columns */
  gap: 16px;
  /* Space between items */
}

.imageContainer {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
}

.imageItem {
  width: 100%;
  height: 150px;
  object-fit: cover;
  border-radius: 8px;
  margin-bottom: 8px;
}

.form-select {
  width: 100%;
  /* max-width: 120px;  */
  /* Optional, limits dropdown width */
}

.chat-page {
  display: flex;
  height: 86vh;
  font-family: Arial, sans-serif;
}

.sidebar {
  width: 25%;
  background-color: #f8f9fa;
  border-right: 1px solid #ddd;
  padding: 1rem;
  overflow-y: auto;
}

.search-bar input {
  width: 100%;
  padding: 0.5rem;
  margin-bottom: 1rem;
  border: 1px solid #ddd;
  border-radius: 5px;
}

.section h5 {
  margin-bottom: 0.5rem;
  color: #7367f0;
  font-size: 22px;
}

.user-info{
  display: flex;
}

.message-heading {
  font-weight: bold;
  font-size: 1rem;
}

.message-text {
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
}


.chat-item,
.contact-item {
  display: flex;
  margin-bottom: 1rem;
  padding: 0.5rem;
  border-radius: 5px;
  cursor: pointer;
}

.chat-itemActive {
  display: flex;
  margin-bottom: 1rem;
  padding: 0.5rem;
  border-radius: 5px;
  cursor: pointer;
  background-color: #191919;
}

.chat-item.active {
  background-color: #e9ecef;
}

.avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 0.75rem;
}

.chat-details,
.contact-details {
  flex: 1;
}

.chat-time {
  font-size: 0.8rem;
  color: #acaab1;
}

.chat-area {
  flex: 1;
  display: flex;
  flex-direction: column;
  /* padding: 0rem 1rem; */
}

.chat-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #ddd;
  padding-bottom: 0.5rem;
  /* margin-bottom: 1rem; */
  position: sticky;
}

.sticky-header {
  position: sticky;
  z-index: 1000; /* Ensure it stays above other elements */
  background-color: #fff; /* Ensure it doesn't inherit transparency */
  padding: 10px; /* Optional: add padding */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Optional: add shadow for better visibility */
}

.sticky-headerbottom {
  position: sticky;
  bottom: 0; /* Sticks to the bottom of the viewport or container */
  z-index: 10; /* Keeps it on top of other elements if overlapping occurs */
  background-color: #fff; /* Ensures it doesn't show transparent gaps */
  box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1); /* Optional: Adds a subtle shadow */
}


.chat-messages {
  flex: 1;
  overflow-y: scroll;
  padding: 0.5rem 0 5.0rem;
  padding-right: 10px;
}

.message {
  max-width: 100%;
  /* margin-bottom: 1rem; */
}

.message.sent {
  align-self: flex-end;
  /* padding: 0.75rem; */
  border-radius: 10px;
  text-align: right;
  color: white;
  display: flex;
  justify-content: end;
  margin-bottom: 0px !important;
}

.message.received {
  align-self: flex-start;
  padding: 0.75rem;
  border-radius: 10px;
}

.chat-submessage{
  background-color: #191919;
   max-width : 250px;
   padding : 10px;
   border-radius: 10px;
}

.chat-input {
  display: flex;
  align-items: center;
  /* position: sticky; Makes the input box sticky */
  /* bottom: 0; Sticks to the bottom of the chat area */
  background-color: #fff; /* Ensures it has a background */
  border-top: 1px solid #ddd;
  padding: 0.5rem;
  z-index: 10; /* Keeps it above other elements */
}


.chat-input input {
  flex: 1;
  border: 1px solid #ddd;
  border-radius: 5px;
  /* padding: 0.5rem;
  margin-bottom: 20px; */
}

.send-button {
  background-color: #685dd8;
  color: #fff;
  border: none;
  border-radius: 5px;
  padding: 0.5rem 1rem;
  margin-left: 0.5rem;
}

.search-container {
  position: relative;
  display: inline-block;
  width: 300px; /* Adjust width as needed */
}

.search-container input {
  width: 100%;
  padding: 5px 10px 10px 40px; /* Add padding to make space for the icon */
  border: 1px solid #ccc;
  border-radius: 10px; /* Rounded corners */
  font-size: 16px;
  outline: none;
}

.search-container .search-icon {
  position: absolute;
  top: 50%;
  left: 10px; /* Position of the icon */
  transform: translateY(-50%);
  display: flex;
  align-items: center;
}

.search-container .search-icon img {
  width: 16px; /* Adjust icon size */
  height: 16px;
}

.custom-input::placeholder {
  color: #888; /* Change placeholder color */
  font-style: italic; /* Optional: Style placeholder */
}

.custom-input {
  color: #333; /* Change text color */
  font-size: 16px; /* Adjust font size */
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px;
}

.message-input-container {
  background-color: #f8f9fa; /* Light gray background */
}

input.form-control {
  flex: 1; /* Ensures the input takes up remaining space */
}

button.btn-primary {
  padding: 0.5rem 1rem;
  font-weight: 500;
}





/* Responsive layout */
@media (max-width: 600px) {
  .imageGrid {
    grid-template-columns: repeat(2, 1fr);
    /* Two columns on smaller screens */
  }
}

/* This class centers the image and status container */
.imageGridInfo {
  display: flex;
  flex-direction: column;
  gap: 10px;
  /* Stack image and status vertically */
  justify-content: center;
  align-items: center;
  text-align: center;
}

/* This class ensures the image is properly centered and has fixed max size */
.imageWrapper img {
  object-fit: cover;
  width: 200px;
  height: 200px;
  margin-bottom: 10px;
  /* Space between image and status */
}

/* This class ensures status is positioned correctly under the image */
.statusWrapper {
  margin-top: 5px;
  /* Space between image and status */
  font-size: 14px;
  font-weight: 500;
  color: #555;
}

/* Optional: Styling for status */
.statusLabelInfo {
  color: #000;
  font-weight: bold;
}

.statusLabelInfo:before {
  content: "Status: ";
}


.MuiPickersLayout-root .css-1st8yxe-MuiPickersLayout-root{

}

.css-1k4oq0i-MuiPaper-root-MuiPickersPopper-paper{
 z-index: -999;
margin-left: 20px !important;
}



.css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root {
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.4375em;
    letter-spacing: 0.00938em;
    color: rgba(0, 0, 0, 0.87);
    box-sizing: border-box;
    position: relative;
    cursor: text;
    display: -webkit-inline-box;
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
    height :50px;
    width : 200px;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    position: relative;
    border-radius: 4px;
    padding-right: 14px;
}

.css-10o2lyd-MuiStack-root {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    overflow: auto;
    padding-top: 0px !important;
}

.css-1r8g3te-MuiFormControl-root-MuiTextField-root .MuiOutlinedInput-root {
    width: 207px;
}


.mySwiper {
  display: flex;
  flex-direction: row;
  overflow: hidden;
}

.form-control:focus {
    color: var(--bs-body-color);
    background-color: 1px solid black;
    border-color: black;
    outline: 0;
    box-shadow: none !important;
}

.css-sx5hge {
    display: flex;
    flex-direction: column;
    overflow: auto;
    padding-top: 8px;
    width: 207px;

}

/* Custom CSS for focus */
.custom-focus .MuiOutlinedInput-root.Mui-focused {
border-color: none;
}

/* Chat Page : - */

.stickyposition{
  position: sticky;
   bottom: 0;
  
}

.chat-input.sticky-headerbottom {
  position: sticky; /* Makes the element sticky */
  bottom: 0; /* Sticks to the bottom of the viewport */
  z-index: 10; /* Ensures it appears above other content */
  background-color: #fff; /* Add background to avoid transparency issues */
  padding: 10px; /* Add some spacing for a better look */
  box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1); /* Optional shadow for separation */
}


.sticky-header {
  position: sticky;
  top: 0;
  z-index: 10;
  background-color: #fff;
  /* padding: 10px; */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.chat-input.sticky-headerbottom {
  position: sticky;
  bottom: 0;
  z-index: 10;
  background-color: #fff;
  padding: 10px;
  box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1);
}

.css-q67a6s-control {
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    cursor: pointer;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex-wrap: wrap;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    min-height: 38px;
    outline: 0 !important;
    position: relative;
    -webkit-transition: all 100ms;
    transition: all 100ms;
    background-color: #dee2e694;
    border-color: hsl(0, 0%, 80%);
    border-radius: 6px 0 0 6px;
    border-style: solid;
    border-width: 1px;
    box-sizing: border-box;
    width: 98% !important;
    height: 48px;
    border: 1px solid var(--inputBorder--);
    padding: 16px;
    font-size: 14px;
}


.css-b4lsws-control {
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    cursor: pointer;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex-wrap: wrap;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    min-height: 38px;
    outline: 0 !important;
    position: relative;
    -webkit-transition: all 100ms;
    transition: all 100ms;
    background-color: #dee2e694;
    border-color: hsl(0, 0%, 90%);
    border-radius: 6px 0 0 6px;
    border-style: solid;
    border-width: 1px;
    box-sizing: border-box;
    width: 100%;
    height: 48px;
    border: 1px solid var(--inputBorder--);
    padding : 0px !important;
    /* padding: 16px; */
    font-size: 14px;
}


.withdrawal-page .primeMain ul li::before {
  content: " ";
  position: absolute;
  top: 6px;
  left: -19px;
  height: 7px;
  width: 7px;
  border: 1px solid #b6b6b6;
  border-width: 2px 2px 0 0;
  transform: rotate(45deg);
}


.css-olqui2-singleValue {
  grid-area: 1 / 1 / 2 / 3;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: black !important;
  font-weight: 400;
  margin-left: 2px;
  margin-right: 2px;
  box-sizing: border-box;
}