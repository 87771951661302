@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap');
/* font-family: 'Roboto', sans-serif; */

body {
  margin: 0;
  /* font-family: 'Inter', sans-serif !important; */
  font-family: 'Roboto', sans-serif !important;
  background: #F1F5F9 !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

